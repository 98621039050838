<template>
  <div class="card p-4 rounded">
    <div class="d-flex justify-content-between align-items-center">
      <div class="font-weight-bold">
        Programar entrega
      </div>
      <div class="d-flex align-items-center">
        <div role="button" @click="handleEdit">
          <z-icon v-show="showEditIcon" name="Edit" class="mr-3" />
        </div>
        <z-dropdown
          v-model="scheduleOption"
          :options="dateOptions"
          :show-header="false"
        />
      </div>
    </div>
    <div v-show="showScheduleOption" class="pt-3">
      <div class="d-flex mb-3">
        <z-date-picker
          v-model="selectedDate"
          :disabled-dates="datePickerConfig.disabledDates"
          class="mr-3"
        />
        <z-dropdown
          v-model="selectedHour"
          :options="hourOptions"
          :show-header="false"
          variant="white"
          max-height="200px"
        />
      </div>
      <z-button @click="handleSave">
        Guardar cambios
      </z-button>
    </div>
    <div v-show="showScheduledDate" class="pt-2">
      <div class="text-sm">
        {{ parsedDate }}
      </div>
    </div>
  </div>
</template>

<script>
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import { format } from "@zubut/common/src/utils/time";
import {
  isToday,
  endOfYesterday,
  startOfHour,
  setHours,
  getHours
} from "date-fns";
import * as mutation from "@/store/modules/express/mutations-types";

export default {
  name: "ExpressCreationSchedule",

  components: {
    ZDatePicker,
    ZDropdown
  },

  data() {
    return {
      selectedDate: new Date(),
      dateOptions: [
        { value: false, text: "Ahora" },
        { value: true, text: "Programar" }
      ],
      scheduleOption: true,
      datePickerConfig: {
        disabledDates: {
          to: endOfYesterday()
        }
      },
      maxHour: 21,
      minHour: 10,
      editing: false
    };
  },

  computed: {
    hourOptions() {
      if (isToday(this.selectedDate)) {
        const currentTime = new Date();
        const currentHour = getHours(currentTime);
        let minHour =
          currentHour > this.minHour ? currentHour + 2 : this.minHour;

        return this.createNumberOptions({ from: minHour, to: this.maxHour });
      }
      return this.createNumberOptions({ from: this.minHour, to: this.maxHour });
    },

    selectedHour: {
      get() {
        return getHours(this.selectedDate);
      },
      set(val) {
        this.selectedDate = setHours(this.selectedDate, val);
      }
    },

    showScheduleOption() {
      return this.scheduleOption === true && this.editing;
    },

    showScheduledDate() {
      return (
        !this.editing && this.stateSelectedDate && this.scheduleOption === true
      );
    },

    showEditIcon() {
      return (
        !this.editing &&
        this.stateSelectedDate !== null &&
        this.scheduleOption === true
      );
    },

    stateSelectedDate() {
      return this.$store.state.express.additionalOptions.scheduled;
    },

    parsedDate() {
      return this.stateSelectedDate
        ? format(
            this.stateSelectedDate,
            "'Agendando para el:' d 'de' MMMM, 'a las' H:mm 'hrs.'"
          )
        : "";
    }
  },

  watch: {
    selectedDate: {
      immediate: true,
      handler(newDate, oldDate) {
        // The scheduled date must be done at least one hour after the current time
        // and at least equal or greater to the min hour
        if (
          newDate?.getTime() !== oldDate?.getTime() &&
          isToday(newDate) &&
          this.selectedHour
        ) {
          const currentHour = getHours(new Date());
          const isOneHourAhead = this.selectedHour >= currentHour + 1;
          const isWithinMinHour = this.selectedHour >= this.minHour;

          if (!isOneHourAhead || !isWithinMinHour) {
            const newHour =
              currentHour > this.minHour ? currentHour + 2 : this.minHour;
            this.selectedDate = startOfHour(setHours(newDate, newHour));
          }
        }
      }
    },
    scheduleOption(newVal) {
      if (newVal === true) {
        this.selectedDate = new Date();
        this.handleEdit();
      } else {
        this.selectedDate = null;
        this.handleSave();
      }
    }
  },

  created() {
    this.selectedDate = this.stateSelectedDate;

    if (!this.selectedDate) {
      this.scheduleOption = false;
    } else {
      this.scheduleOption = true;
    }
  },

  methods: {
    createNumberOptions({ from, to }) {
      let i = 0;
      const options = [];
      const tempFrom = from > to ? to : from;
      to = to < tempFrom ? tempFrom : to;
      from = tempFrom;
      i = from;

      // TODO: Support 30 minutes interval, it needs changes in dropdown
      // to support object values
      while (i <= to) {
        const option = {
          value: i,
          text: i < 10 ? `0${i}:00 hrs.` : `${i}:00 hrs.`
        };
        options.push(option);
        i++;
      }

      return options;
    },
    handleSave() {
      this.editing = false;
      this.$store.commit(
        `express/${mutation.SET_ADDITIONAL_OPTIONS_SCHEDULED}`,
        this.selectedDate
      );
    },
    handleEdit() {
      this.editing = true;
    }
  }
};
</script>
