<template>
  <div class="z-payment-method-select border rounded">
    <div
      role="button"
      class="payment-method"
      :class="{ active: value === 'card' }"
      @click="handleSelect('card')"
    >
      <z-icon name="Card" :variant="value === 'card' ? 'active' : 'normal'" />
      <span>Tarjeta</span>
    </div>
    <div
      role="button"
      class="payment-method"
      :class="{ active: value === 'wallet' }"
      @click="handleSelect('wallet')"
    >
      <z-icon
        name="Wallet"
        :variant="value === 'wallet' ? 'active' : 'normal'"
      />
      <span>Wallet</span>
    </div>
  </div>
</template>

<script>
import ZIcon from "./ZIcon";

export default {
  name: "ZPaymentMethodSelect",

  components: {
    ZIcon
  },

  props: {
    value: {
      type: String,
      validator: val => ["card", "wallet"].indexOf(val) > -1
    }
  },

  methods: {
    handleSelect(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.z-payment-method-select {
  display: flex;
  padding: 4px;

  .payment-method {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    font-weight: 500;
    padding: 6px;

    span {
      margin-left: 8px;
    }

    &.active {
      color: var(--primary);
      background-color: var(--primary-bg);
    }

    &:first-child {
      margin-right: 6px;
    }
  }
}
</style>
