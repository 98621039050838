<template>
  <div>
    <express-creation-address
      v-for="(destination, index) in destinations"
      :key="`destiantion-${index}`"
      :index="index"
      :variant="index === 0 ? 'origin' : 'destination'"
      class="mt-3"
    />
    <b-row
      v-if="showAddDestination"
      align-content="end"
      align-h="end"
      class="mt-md-3"
    >
      <b-col
        v-if="showAddFromFavoriteAddress"
        cols="12"
        md="auto"
        class="pr-md-0"
      >
        <favorite-addresses
          class="mt-3 mt-md-0"
          type="button"
          size="sm"
          @selected="addFavoriteAddressDestination"
        />
      </b-col>
      <b-col cols="12" md="auto">
        <z-button
          class="mt-3 mt-md-0"
          variant="primary-alt"
          size="sm"
          :disabled="!showAddDestination"
          @click="addNewDestination"
        >
          <div class="d-flex align-items-center">
            <z-icon class="mr-2" name="Add" variant="active" size="22" />
            Agregar destino
          </div>
        </z-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ExpressCreationAddress from "./ExpressCreationAddress";
import FavoriteAddresses from "@/app/components/FavoriteAddresses";
import * as mutation from "@/store/modules/express/mutations-types";

export default {
  name: "ExpressCreationDestinations",

  components: {
    ExpressCreationAddress,
    FavoriteAddresses
  },

  computed: {
    destinations() {
      return this.$store.getters["express/getDestinations"];
    },
    validDestinations() {
      return this.destinations.filter(d => d.data?.address?.address);
    },
    showAddDestination() {
      return this.destinations.length < 5;
    },
    showAddFromFavoriteAddress() {
      return this.validDestinations.length >= 2 && this.showAddDestination;
    }
  },

  methods: {
    addNewDestination() {
      const index = this.destinations.length;
      this.$store.commit(`express/${mutation.ADD_DESTINATION}`, {
        index,
        data: {
          address: "",
          name: "",
          phone: "",
          information: "",
          id: null
        }
      });
    },
    addFavoriteAddressDestination(favoriteAddresse) {
      const index = this.destinations.length;
      this.$store.commit(`express/${mutation.ADD_DESTINATION}`, {
        index,
        data: {
          address: {
            address: favoriteAddresse.address,
            position: favoriteAddresse.position
          },
          phone: favoriteAddresse.phone || "",
          name: favoriteAddresse.name || "",
          information: favoriteAddresse.information || ""
        }
      });
    }
  }
};
</script>
