<template>
  <div class="card p-4 rounded">
    <b-row>
      <b-col cols="12" lg="4">
        <div class="font-weight-bold">Tiempo</div>
        <div>{{ rates.minutes }} min. o menos</div>
      </b-col>
      <b-col cols="12" lg="4" class="mt-3 mt-lg-0">
        <div class="font-weight-bold">Kms</div>
        <div>{{ rates.km }} kms.</div>
      </b-col>
      <b-col cols="12" lg="4" class="mt-3 mt-lg-0">
        <div class="font-weight-bold">Total</div>
        <div>{{ rates.cost }} {{ rates.currency }}</div>
      </b-col>
    </b-row>
    <hr />
    <b-row align-h="between">
      <b-col class="d-flex align-items-center" cols="12" sm="auto">
        <div class="font-weight-bold">Forma de pago</div>
      </b-col>
      <b-col class="mt-3 mt-sm-0" cols="12" sm="auto">
        <z-payment-method-select v-model="paymentMethod" />
      </b-col>
    </b-row>
    <hr />
    <div v-show="paymentMethod === 'card'">
      <z-cards-dropdown
        v-model="selectedCardId"
        :credit-cards="creditCards"
        @add-card="handleAddCard"
      >
      </z-cards-dropdown>
    </div>
    <div v-show="paymentMethod === 'wallet'">
      <wallet-balance size="sm" />
    </div>
  </div>
</template>

<script>
import ZCardsDropdown from "@zubut/common/src/components/ZCardsDropdown";
import WalletBalance from "@/app/components/WalletBalance";
import ZPaymentMethodSelect from "@zubut/common/src/components/ZPaymentMethodSelect";
import * as mutation from "@/store/modules/express/mutations-types";

export default {
  name: "ExpressCreationPaymentMethod",

  components: {
    ZCardsDropdown,
    ZPaymentMethodSelect,
    WalletBalance
  },

  computed: {
    creditCards() {
      return this.$store.getters["user/getCreditCards"];
    },
    paymentMethod: {
      get() {
        return this.$store.state.express.paymentMethod;
      },
      set(val) {
        this.$store.commit(`express/${mutation.SET_PAYMENT_METHOD}`, val);
      }
    },
    rates() {
      return this.$store.state.express.rates;
    },
    selectedCardId: {
      get() {
        return this.$store.state.express.cardId;
      },
      set(val) {
        this.$store.commit(`express/${mutation.SET_CARD_ID}`, val);
      }
    }
  },

  methods: {
    handleAddCard() {
      this.$router.push({ name: "payment-methods" });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-icon {
  height: 24px;
}
</style>
