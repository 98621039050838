<template>
  <div>
    <z-map-expandable
      :destinations="destinations"
      calculate-route
      @rate-change="rateChange"
    />
    <delivery-methods
      class="w-100 bg-white"
      :prices="vehicleRates"
      :eco-allowed="false"
      :vehicle-type.sync="vehicleType"
      not-allowed-message="Tarifa no establecida"
    />
  </div>
</template>

<script>
import DeliveryMethods from "@zubut/common/src/components/DeliveryMethods";
import ZMapExpandable from "@zubut/common/src/components/ZMapExpandable";
import * as mutation from "@/store/modules/express/mutations-types";

export default {
  name: "ExpressCreationMap",

  components: {
    DeliveryMethods,
    ZMapExpandable
  },

  computed: {
    destinations() {
      return this.$store.getters["express/getMapDestinations"];
    },
    vehicleRates() {
      return this.$store.getters["express/getVehicleRates"];
    },
    vehicleType: {
      get() {
        return this.$store.state.express.vehicleType;
      },
      set(val) {
        this.$store.commit(`express/${mutation.SET_VEHICLE_TYPE}`, val);
      }
    }
  },

  watch: {
    vehicleType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$store.dispatch("express/serviceRate");
      }
    }
  },

  methods: {
    rateChange({ km, minutes }) {
      this.$store.dispatch("express/setTimeAndDistance", { km, minutes });
      this.$store.dispatch("express/serviceRate");
    }
  }
};
</script>
