<template>
  <div class="delivery-methods my-3">
    <z-tooltip
      id="motorbike"
      data-test-id="motorbike"
      class="delivery-method"
      :class="{
        selected: vehicleType == vehicleTypes.NUM_MOTORCYCLE,
        disabled: !motorcycleAllowed
      }"
      @click.native="
        handleClick(motorcycleAllowed, vehicleTypes.NUM_MOTORCYCLE)
      "
      :message="motorcycleMessage"
    >
      <div class="delivery-method-content">
        <div class="delivery-method-icon">
          <z-icon
            :variant="
              vehicleType == vehicleTypes.NUM_MOTORCYCLE ? 'active' : 'normal'
            "
            name="Motorcycle"
          />
        </div>
        <div class="delivery-method-text">
          Motocicleta
        </div>
      </div>
    </z-tooltip>
    <z-tooltip
      id="car"
      data-test-id="car"
      class="delivery-method"
      :class="{
        selected: vehicleType == vehicleTypes.NUM_CAR,
        disabled: !carAllowed
      }"
      :message="carMessage"
      @click.native="handleClick(carAllowed, vehicleTypes.NUM_CAR)"
    >
      <div class="delivery-method-content">
        <div class="delivery-method-icon">
          <z-icon
            :variant="vehicleType == vehicleTypes.NUM_CAR ? 'active' : 'normal'"
            name="Car"
          />
        </div>
        <div class="delivery-method-text">
          Auto
        </div>
      </div>
    </z-tooltip>
    <z-tooltip
      id="bike"
      data-test-id="bike"
      class="delivery-method"
      :message="ecoMessage"
      :class="{
        selected: vehicleType == vehicleTypes.NUM_ECO,
        disabled: !ecoAllowed
      }"
      @click.native="handleClick(ecoAllowed, vehicleTypes.NUM_ECO)"
    >
      <div class="delivery-method-content">
        <div class="delivery-method-icon">
          <z-icon
            :variant="vehicleType == vehicleTypes.NUM_ECO ? 'active' : 'normal'"
            name="Bike"
          />
        </div>
        <div class="delivery-method-text">
          Bicicleta
        </div>
      </div>
    </z-tooltip>
  </div>
</template>

<script>
import VehicleTypes from "@/constants/vehicles/type";
import ZTooltip from "@zubut/common/src/components/ZTooltip";
import ZIcon from "./ZIcon";
import { formatMoney } from "@/utils/money";

export default {
  name: "DeliveryMethods",

  components: {
    ZTooltip,
    ZIcon
  },

  props: {
    carAllowed: {
      type: Boolean,
      default: true
    },
    ecoAllowed: {
      type: Boolean,
      default: true
    },
    notAllowedMessage: {
      type: String,
      default: "No disponible en el área"
    },
    notDefinedPrice: {
      type: String,
      default: "El precio varia por hora"
    },
    motorcycleAllowed: {
      type: Boolean,
      default: true
    },
    prices: {
      type: Object,
      default: () => ({
        Motorcycle: -1,
        Eco: -1,
        Car: -1
      })
    },
    vehicleType: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      vehicleTypes: VehicleTypes
    };
  },

  computed: {
    motorcycleMessage() {
      return this.getMessage(this.motorcycleAllowed, this.prices.Motorcycle);
    },
    carMessage() {
      return this.getMessage(this.carAllowed, this.prices.Car);
    },
    ecoMessage() {
      return this.getMessage(this.ecoAllowed, this.prices.Eco);
    }
  },

  methods: {
    handleClick(allowClick, deliveryMethod) {
      if (allowClick) {
        this.$emit("update:vehicleType", deliveryMethod);
      }
    },

    getMessage(isMethodAllowed, price) {
      if (isMethodAllowed) {
        if (price > 0) {
          return formatMoney(price);
        } else if (price && price.min && price.max) {
          return `${formatMoney(price.min)} - ${formatMoney(price.max)} x hora`;
        } else if (price && price.delivery && price.attempt) {
          return `Entrega ${formatMoney(
            price.delivery
          )} - Intento ${formatMoney(price.attempt)}`;
        }
        return this.notDefinedPrice;
      }
      return this.notAllowedMessage;
    }
  }
};
</script>

<style lang="scss" scoped>
.delivery-methods {
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  border: 1px solid #d4d7df;
  border-radius: 10px;
}

.delivery-method {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 0.7rem 0;
  color: $nero;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;

  &.selected {
    color: var(--primary);
    background-color: var(--primary-bg);
  }

  &.disabled {
    color: $shady-lady;
  }
}

.delivery-method-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivery-method-icon {
  width: 20px;
  height: 20px;
  flex-wrap: wrap;
  display: flex;
  margin-right: 0.8rem;
}

@include media-breakpoint-up(lg) {
  .delivery-methods {
    flex-direction: row;
  }
}
</style>
