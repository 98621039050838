<template>
  <div
    v-tooltip="{
      content: message,
      placement: 'top-center',
      classes: ['my-tooltip']
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "ZTooltip",

  props: {
    message: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.tooltip {
  &.my-tooltip {
    .tooltip-inner {
      background: $mortar !important;
      color: $white !important;
      border-radius: 2px !important;
      font-size: 12px;
      padding-right: 10px;
      padding-left: 10px;
      margin-bottom: 6px;
    }
  }
}
</style>
