<template>
  <div
    class="address-data-form rounded border"
    :class="isOrigin ? 'origin' : 'destination'"
  >
    <quick-message
      class="mb-3"
      with-icon
      :show.sync="showQuickMessage"
      :type="quickMessage ? quickMessage.type : 'success'"
      :message="quickMessage ? quickMessage.message : ''"
    />
    <div class="d-flex">
      <div
        class="title font-weight-bold d-flex align-items-start justify-content-between w-100"
      >
        <div class="d-flex">
          <z-icon
            :name="isOrigin ? 'StoreLocation' : 'House'"
            size="20px"
            class="address-data-icon"
          />
          <div>
            {{ cardTitle }}
          </div>
        </div>
        <div>
          <favorite-addresses
            v-if="editEnable"
            @selected="handleFavoriteAddressChange"
          />
          <a v-if="!editEnable" class="ml-auto edit-icon" @click="handleEdit()">
            <z-icon name="Edit" hover />
          </a>
          <a v-if="index >= 2" class="ml-1 edit-icon" @click="handleDelete()">
            <z-icon name="Close" hover />
          </a>
        </div>
      </div>
    </div>
    <form v-if="editEnable">
      <div class="card-margin mt-3">
        <div class="d-flex flex-wrap justify-content-between">
          <z-input-address
            v-model="newAddress.addressText"
            label="Dirección"
            name="address"
            type="text"
            required
            :allow-save="isFormValid"
            :state="validAddress"
            :address-saved="isAddressFavorite"
            @selected="handleAutocompleteAddressChange"
            @save="saveDestinationOnFavorites"
          />
          <z-input
            v-model="newAddress.name"
            class="form-input"
            type="text"
            label="¿Con quién vamos?"
            :state="validName"
          />
          <z-input
            v-model="newAddress.phone"
            class="form-input"
            type="tel"
            label="Teléfono"
            :state="validPhone"
          />
          <z-input
            v-model="newAddress.information"
            class="form-input w-100"
            :state="validExtraInformation"
          >
            <template #label>
              <span class="d-sm-none">Información adicional</span>
              <span class="d-none d-sm-inline">
                Cruces, referencias o información del paquete
              </span>
            </template>
          </z-input>
        </div>
      </div>
      <div class="card-margin">
        <div v-if="showAdditionalOptions" class="mt-3">
          <b-form-checkbox
            v-model="newAddress.signature"
            :value="true"
            name="additional-option-signature"
            class="mb-3"
          >
            Firma en el destino
          </b-form-checkbox>
          <b-form-checkbox
            v-model="newAddress.restrictedItems"
            :value="true"
            name="additional-option-restricted-items"
            class="mb-3"
          >
            Artículos restringidos (+18)
          </b-form-checkbox>
        </div>
        <b-row class="mt-3">
          <b-col cols="12" md="6" xl="3">
            <z-button
              class="w-100"
              size="md"
              variant="secondary"
              @click="handleCancel()"
            >
              Cancelar
            </z-button>
          </b-col>
          <b-col cols="12" md="6" xl="3" class="px-xl-0">
            <z-button
              class="ml-md-2 ml-xl-0 mt-3 mt-md-0 w-100"
              size="md"
              @click="handleSave()"
            >
              Guardar Cambios
            </z-button>
          </b-col>
        </b-row>
      </div>
    </form>
    <div v-else class="subtitle-address card-margin mt-0">
      <div>
        {{ address.addressText }}
      </div>
      <div>
        {{ address.information }}
      </div>
      <div>
        {{ address.phone }}
      </div>
      <div v-if="isOrigin" class="mt-2">
        <div>
          Solicitar firma en destinos: {{ address.signature ? "Si" : "No" }}
        </div>
        <div>
          Contiene artículos restringidos (+18):
          {{ address.restrictedItems ? "Si" : "No" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZInputAddress from "@zubut/common/src/components/ZInputAddress";
import { formatPhone, validateName } from "@zubut/common/src/utils/strings";
import FavoriteAddresses from "@/app/components/FavoriteAddresses";
import * as mutation from "@/store/modules/express/mutations-types";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "ExpressCreationAddress",

  components: {
    FavoriteAddresses,
    ZInputAddress
  },

  props: {
    index: {
      type: Number,
      default: 0
    },
    variant: {
      type: String,
      default: "destination"
    }
  },

  data() {
    return {
      showQuickMessage: false,
      quickMessage: null,
      editing: false,
      address: {
        id: null,
        clientId: "",
        name: "",
        phone: "",
        information: "",
        address: "",
        addressText: "",
        signature: false,
        restrictedItems: false,
        position: null,
        valid: true
      },
      newAddress: {
        id: null,
        clientId: "",
        name: "",
        phone: "",
        information: "",
        address: "",
        addressText: "",
        signature: false,
        restrictedItems: false,
        position: null,
        valid: false
      }
    };
  },

  computed: {
    cardTitle() {
      const name = this.name;
      if (this.savedAddressIsEmpty) {
        return this.isOrigin
          ? `¿Dónde recolectamos el paquete?`
          : `¿Dónde entregamos el paquete?`;
      }
      return this.isOrigin
        ? `Recolectar paquete en ${name}`
        : `Entregar paquete a ${name}`;
    },

    name() {
      return this.address.name;
    },

    editEnable() {
      return this.editing;
    },

    isOrigin() {
      return this.variant === "origin";
    },

    showAdditionalOptions() {
      return this.isOrigin === true;
    },

    validAddress() {
      return (
        this.newAddress?.address?.address?.length > 0 &&
        this.newAddress.valid === true
      );
    },

    validPhone() {
      return this.newAddress.phone.length === 14 ? true : false;
    },

    validName() {
      return validateName(this.newAddress.name) ? true : false;
    },

    validExtraInformation() {
      return this.newAddress.information.length > 0 ? true : null;
    },

    savedAddress() {
      if (this.isOrigin && this.stateAddressIsEmpty && this.defaultAddress) {
        return this.defaultAddress;
      }
      return this.stateAddress;
    },

    savedAddressIsEmpty() {
      return this.savedAddress?.data?.address ? false : true;
    },

    stateAddress() {
      return this.$store.getters["express/getAddress"](this.index);
    },

    stateAddressIsEmpty() {
      return this.stateAddress?.data?.address ? false : true;
    },

    defaultAddress() {
      return this.$store.getters["express/getDefaultAddressParsed"];
    },

    stateAdditionalOptions() {
      return this.$store.getters["express/getAdditionalOptions"];
    },

    isAddressFavorite() {
      return this.newAddress.id ? true : false;
    },

    isFormValid() {
      if (this.validName && this.validPhone && this.validAddress) {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    quickMessage(newMessage) {
      if (newMessage != null) {
        this.showQuickMessage = true;
      } else {
        this.showQuickMessage = false;
      }
    },
    "newAddress.addressText": {
      handler() {
        this.newAddress.valid = false;
      }
    }
  },

  created() {
    this.setStateAddress();
  },

  methods: {
    setStateAddress() {
      if (this.isOrigin && this.stateAddressIsEmpty && this.defaultAddress) {
        this.setDefaultAddress();
      }

      const { id, address, information, name, phone } = this.savedAddress.data;
      this.address.id = id;
      this.address.address = { ...address };
      this.address.addressText = address.address;
      this.address.name = name;
      this.address.phone = formatPhone(phone);
      this.address.information = information;
      this.address.position = address.position ? { ...address.position } : null;

      if (this.isOrigin) {
        this.address.signature = this.stateAdditionalOptions.signature;
        this.address.restrictedItems = this.stateAdditionalOptions.restrictedItems;
      }
    },

    setDefaultAddress() {
      this.$store.commit(
        `express/${mutation.UPDATE_DESTINATION}`,
        this.defaultAddress
      );
    },

    handleEdit() {
      this.editing = true;
      this.newAddress = _cloneDeep(this.address);
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    handleDelete() {
      this.$store.commit(`express/${mutation.DELETE_DESTINATION}`, this.index);
    },

    handleCancel() {
      this.editing = false;
      this.quickMessage = null;
      this.showQuickMessage = false;
      this.newAddress = {
        id: null,
        clientId: "",
        name: "",
        phone: "",
        information: "",
        address: "",
        addressText: "",
        signature: false,
        restrictedItems: false,
        position: null,
        valid: false
      };
    },

    handleSave() {
      if (!this.isFormValid) {
        this.showQuickMessage = true;
        this.quickMessage = {
          message: "Comprueba tus datos de contacto",
          type: "error"
        };
      } else {
        this.showQuickMessage = false;
        const formattedDestination = {
          index: this.index,
          data: {
            address: this.newAddress.address,
            name: this.newAddress.name,
            phone: this.newAddress.phone,
            information: this.newAddress.information
          }
        };
        this.address = { ...this.newAddress };
        this.$store.commit(
          `express/${mutation.UPDATE_DESTINATION}`,
          formattedDestination
        );
        this.editing = false;

        if (this.isOrigin) {
          this.$store.commit(
            `express/${mutation.SET_ADDITIONAL_OPTIONS_SIGNATURE}`,
            this.newAddress.signature
          );
          this.$store.commit(
            `express/${mutation.SET_ADDITIONAL_OPTIONS_RESTRICTED_ITEMS}`,
            this.newAddress.restrictedItems
          );
        }
      }
    },

    handleAutocompleteAddressChange(address) {
      this.newAddress.address = {
        address: address.address,
        position: address.position
      };
      this.newAddress.addressText = address.address;
      this.newAddress.position = address.position;
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    handleFavoriteAddressChange(address) {
      this.newAddress.id = address.id;
      this.newAddress.clientId = address.clientId;
      this.newAddress.address = {
        address: address.address,
        position: address.position
      };
      this.newAddress.addressText = address.address;
      this.newAddress.phone = formatPhone(address.phone);
      this.newAddress.name = address.name;
      this.newAddress.information = address.information || "";
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    saveDestinationOnFavorites() {
      if (this.isFormValid) {
        const favorite = {
          id: this.newAddress.id,
          clientId: this.newAddress.clientId,
          address: this.newAddress.address.address,
          position: this.newAddress.address.position,
          name: this.newAddress.name,
          phone: this.newAddress.phone,
          information: this.newAddress.information
        };
        this.$store
          .dispatch("user/createOrUpdateFavoriteAddress", favorite)
          .then(res => {
            this.newAddress.id = res.id;
            this.newAddress.clientId = res.clientId;
            this.quickMessage = {
              message: "La dirección se ha actualizado correctamente",
              type: "success"
            };
          })
          .catch(err => {
            this.$captureError(err);
            this.quickMessage = {
              message: "Ocurrió un problema al actualizar la dirección",
              type: "error"
            };
          });
      } else {
        this.showQuickMessage = true;
        this.quickMessage = {
          message: "Comprueba tus datos de contacto",
          type: "error"
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.address-data-form {
  padding: 20px;

  &.origin {
    background-color: $ghost-white;
  }

  .address-data-icon {
    margin-right: 12px;
  }

  .title {
    font-size: 14px;
  }

  .edit-icon {
    cursor: pointer;
  }

  .subtitle {
    padding-top: 20px;
    font-size: 12px;
    color: $comet;
  }

  .subtitle-address {
    font-size: 12px;
    color: black;
    margin-top: 8px;
  }

  .checkbox-text {
    font-size: 12px;
    padding-left: 8px;
    margin: 0;
    margin-top: 2px;
  }

  .form-input {
    width: 100%;
    margin: 6px 0;
  }

  @include media-breakpoint-up(md) {
    padding: 20px 20px 20px 12px;

    .form-input {
      width: 49%;
    }

    .card-margin {
      margin-left: 32px;
    }
  }
}
</style>
