<!--TODO: Ask for delete all components on folder ExpressCreation (Express is going to be deprecated)-->
<template>
  <div>
    <z-delivery-creation-modal
      v-if="!showConfirmation"
      title="Crear entrega Express"
      subtitle="Puedes añadir hasta 5 destinos extra"
      :service-type="mulitPointType"
      :enable-create="enableCreate"
      :creating="isCreating"
      @cancel="handleClose"
      @hide="handleClose"
      @create="handleCreate"
    >
      <quick-message
        :title="quickMessage.title"
        :message="quickMessage.message"
        :show.sync="showQuickMessage"
        :type="quickMessage.type"
        with-icon
        class="mt-2"
      />
      <b-row>
        <b-col lg="6">
          <express-creation-destinations />
        </b-col>
        <b-col lg="6">
          <express-creation-map class="mt-3" />
          <express-creation-schedule class="mt-3" />
          <express-creation-payment-method class="mt-3" />
        </b-col>
      </b-row>
    </z-delivery-creation-modal>
    <create-service-success-modal
      :show.sync="showConfirmation"
      variant="express"
      :destination="confirmationAddress"
    >
    </create-service-success-modal>
  </div>
</template>

<script>
import ExpressCreationDestinations from "./ExpressCreationDestinations";
import ExpressCreationMap from "./ExpressCreationMap";
import ExpressCreationPaymentMethod from "./ExpressCreationPaymentMethod";
import ExpressCreationSchedule from "./ExpressCreationSchedule";
import ServiceType from "@zubut/common/src/constants/services/type";
import ZDeliveryCreationModal from "@/app/components/ZDeliveryCreationModal";
import CreateServiceSuccessModal from "@/app/components/CreateServiceSuccessModal";

export default {
  name: "ExpressCreation",

  components: {
    CreateServiceSuccessModal,
    ExpressCreationMap,
    ExpressCreationDestinations,
    ExpressCreationPaymentMethod,
    ExpressCreationSchedule,
    ZDeliveryCreationModal
  },

  data() {
    return {
      showQuickMessage: false,
      quickMessage: {
        title: "Error",
        message: "",
        type: "error"
      },
      mulitPointType: ServiceType.NUM_MULTI_POINT,
      isCreating: false,
      showConfirmation: false,
      confirmationAddress: {
        address: "",
        name: "",
        date: null
      }
    };
  },

  computed: {
    enableCreate() {
      return this.$store.getters["express/isFormValid"] && !this.isCreating;
    }
  },

  watch: {
    quickMessage(newMessage) {
      if (newMessage != null) {
        this.showQuickMessage = true;
      } else {
        this.showQuickMessage = false;
      }
    },

    showConfirmation(val) {
      if (val === false) {
        this.handleClose();
      }
    }
  },

  methods: {
    handleClose() {
      this.$router.go(-1);
    },
    handleCreate() {
      this.isCreating = true;
      this.$store
        .dispatch("express/requestService")
        .then(res => {
          this.showConfirmation = true;
          const origin = res.response.destinations[0];
          const scheduled = res.response.additional.scheduled;
          this.confirmationAddress.address = origin.address;
          this.confirmationAddress.name = origin.name;
          this.confirmationAddress.date = scheduled
            ? new Date(scheduled * 1000)
            : null;
        })
        .catch(err => {
          let message = err.message;
          // TODO: Remove this when message is returned in spanish
          if (err.name === "Not enough funds in the wallet") {
            message = "No cuentas con suficientes fondos en tu Wallet";
          }

          this.quickMessage = {
            title: "Error",
            type: "error",
            message
          };
        })
        .finally(() => {
          this.isCreating = false;
        });
    }
  }
};
</script>
