<template>
  <div>
    <div v-if="!hasCards">
      <z-button
        variant="primary-alt"
        size="lg"
        class="w-100"
        @click="handleAddCard"
      >
        <div class="d-flex align-items-center justify-content-center">
          <z-icon class="mr-2" name="Add" variant="active" size="22" /> Agregar
          tarjeta de crédito / débito
        </div>
      </z-button>
    </div>
    <z-dropdown
      v-else
      :value="value"
      @input="handleInput"
      :options="payments"
      max-height="230px"
      name="Selecciona método de pago"
      :show-header="false"
    >
      <template #option="{ option }">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <amex-icon
              v-if="option.brandShort === 'american_express'"
              class="card-icon"
            />
            <mc-icon
              v-else-if="
                option.brandShort === 'mc' || option.brandShort === 'mastercard'
              "
              class="card-icon"
            />
            <visa-icon
              v-else-if="option.brandShort === 'visa'"
              class="card-icon"
            />
          </div>
          <div>
            <span class="font-weight-semi-bold">{{ option.brandName }}</span>
            *{{ option.last4 }}
          </div>
        </div>
      </template>
      <template #button-content="{ optionData: option }">
        <div v-if="option" class="d-flex align-items-center">
          <div class="mr-2">
            <amex-icon
              v-if="option.brand.toLowerCase() === 'american_express'"
              class="card-icon"
            />
            <mc-icon
              v-else-if="
                option.brand.toLowerCase() === 'mc' ||
                  option.brand.toLowerCase() === 'mastercard'
              "
              class="card-icon"
            />
            <visa-icon
              v-else-if="option.brand.toLowerCase() === 'visa'"
              class="card-icon"
            />
          </div>
          <div>
            <span class="font-weight-bold">
              {{ getBrandName(option.brand) }}
            </span>
            *{{ option.last4 }}
          </div>
        </div>
        <div v-else>Selecciona un método de pago</div>
      </template>
    </z-dropdown>
  </div>
</template>

<script>
import ZButton from "./ZButton";
import ZDropdown from "./ZDropdown";
import ZIcon from "./ZIcon";
import AmexIcon from "../assets/icons/ic_amex.svg";
import McIcon from "../assets/icons/ic_mastercard.svg";
import VisaIcon from "../assets/icons/ic_visa.svg";

export default {
  name: "ZCardsDropdown",

  components: {
    ZButton,
    AmexIcon,
    McIcon,
    VisaIcon,
    ZDropdown,
    ZIcon
  },

  props: {
    creditCards: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Object],
      default: null
    },
    useDefault: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    payments() {
      return this.creditCards.map(card => {
        const brandShort = card.brand.toLowerCase();
        return {
          ...card,
          value: card.id,
          text: `${card.brand} ${card.last4}`,
          brandShort: brandShort,
          brandName: this.getBrandName(brandShort)
        };
      });
    },
    hasCards() {
      return this.creditCards.length > 0;
    }
  },

  created() {
    if (this.useDefault) {
      const defaultCard = this.creditCards.find(card => card.default === true);
      if (defaultCard) {
        this.handleInput(defaultCard.id);
      }
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
    handleAddCard() {
      this.$emit("add-card");
    },
    getBrandName(shortName) {
      shortName = shortName.toLowerCase();
      if (shortName === "mc" || shortName === "mastercard") {
        return "Mastercard";
      } else if (shortName === "american_express") {
        return "American Express";
      } else if (shortName === "visa") {
        return "Visa";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.card-icon {
  height: 24px;
}
</style>
